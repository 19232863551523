import type { ReactNode } from 'react';
import React from 'react';
import { styled } from '@compiled/react';

import { N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { TemplateIcon } from '@confluence/template-card';

import type { TemplatePreviewTemplate } from './templatePreviewHelpers';
import { TOP_BAR_HEIGHT, VERTICAL_MARGIN, DISTANCE_FROM_ITEM } from './measurementConstants';

type Props = {
	template?: TemplatePreviewTemplate;
	children: ReactNode;
	isBottomPositioned?: boolean;
};

const TEMPLATE_INFO_HEIGHT = 53;
const PREVIEW_MIN_HEIGHT = 540;
const PREVIEW_MIN_WIDTH = 480;
const TEMPLATE_PANEL_WIDTH = 300;

const SHORT_BROWSER_HEIGHT = TOP_BAR_HEIGHT + VERTICAL_MARGIN * 2 + PREVIEW_MIN_HEIGHT;
const NARROW_BROWSER_WIDTH = TEMPLATE_PANEL_WIDTH + DISTANCE_FROM_ITEM + PREVIEW_MIN_WIDTH;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateContainer = styled.div<{
	isBottomPositioned: boolean;
}>(
	{
		width: '40vw',
		maxWidth: '600px',
		minWidth: `${PREVIEW_MIN_WIDTH}px`,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: ({ isBottomPositioned }) => (isBottomPositioned ? '80vh' : '85vh'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		maxHeight: ({ isBottomPositioned }) => (isBottomPositioned ? '770px' : '790px'),
		minHeight: `${PREVIEW_MIN_HEIGHT}px`,
		// Beware: we need to make this element (and related others) have an
		// `overlay` background so that it stands out against the page in dark
		// theme. But what it's rendering is a mini-page, whose elements (outside
		// of our control) assume their elevation to be `surface`. If any of them
		// explicitly start drawing in `elevation.surface`, they'll look darker
		// than the rest of the preview's background (in dark theme).
		background: token('elevation.surface.overlay', 'white'),
		borderRadius: '3px',
		boxShadow: token(
			'elevation.shadow.overlay',
			'0 0 1px rgba(9, 30, 66, 0.31), 0 3px 5px rgba(9, 30, 66, 0.2)',
		),

		display: 'flex',
		flexDirection: 'column',
	},
	// Do not try to convert this section to object notation, at least until we
	// update our @compiled/babel-plugin (0.16.3 at time of writing) — and even
	// then, be sure to verify the results.
	// See: https://atlassian.slack.com/archives/C017XR8K1RB/p1679961211073429
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
    @media screen and (max-height: ${SHORT_BROWSER_HEIGHT}px) {
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
      height: calc(100vh - ${TOP_BAR_HEIGHT + VERTICAL_MARGIN * 2}px);
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
      min-height: calc(100vh - ${TOP_BAR_HEIGHT + VERTICAL_MARGIN * 2}px);
    }

    @media screen and (max-width: ${NARROW_BROWSER_WIDTH}px) {
      display: none;
    }
  `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateInfo = styled.div({
	height: `${TEMPLATE_INFO_HEIGHT}px`,
	flexShrink: 0,
	flexGrow: 0,
	padding: `14px ${token('space.300', '24px')}`,
	boxSizing: 'border-box',
	display: 'flex',
	alignItems: 'center',
	borderBottom: `1px solid ${token('color.border', N40)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateName = styled.div({
	font: token('font.heading.small'),
	marginLeft: token('space.050', '4px'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	'flex-grow': '1',
	'flex-shrink': '1',
	overflow: 'hidden',
	// height: without this scroll and % heights of child elements is broken in Safari
	height: `calc(100% - ${TEMPLATE_INFO_HEIGHT}px)`,
});

export const PreviewFrame = ({ template, children, isBottomPositioned = false }: Props) => (
	<TemplateContainer isBottomPositioned={isBottomPositioned}>
		{template && (
			<TemplateInfo>
				<TemplateIcon template={template} size="small" />
				<TemplateName>{template.name}</TemplateName>
			</TemplateInfo>
		)}
		<Wrapper>{children}</Wrapper>
	</TemplateContainer>
);
